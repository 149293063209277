<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Day of week</label>
      <b-form-select v-model="day" :options="days"></b-form-select>
      <span class="form-text text-muted">Select a day of the week.</span>
    </div>

    <!-- Delivery Toggle -->
    <div class="form-group">
      <label>Enable Delivery</label>
      <b-form-checkbox v-model="enableDelivery" switch>
        {{ enableDelivery ? "Enabled" : "Disabled" }}
      </b-form-checkbox>
    </div>

    <!-- Delivery Timings -->
    <div class="form-group" v-if="enableDelivery">
      <label>Delivery Open Time</label>
      <b-form-timepicker v-model="delivery_open_time" locale="en"></b-form-timepicker>
      <span class="form-text text-muted">Choose the delivery opening time.</span>
    </div>
    <div class="form-group" v-if="enableDelivery">
      <label>Delivery Close Time</label>
      <b-form-timepicker v-model="delivery_close_time" locale="en"></b-form-timepicker>
      <span class="form-text text-muted">Choose the delivery closing time.</span>
    </div>

    <!-- Collection Toggle -->
    <div class="form-group">
      <label>Enable Collection</label>
      <b-form-checkbox v-model="enableCollection" switch>
        {{ enableCollection ? "Enabled" : "Disabled" }}
      </b-form-checkbox>
    </div>

    <!-- Collection Timings -->
    <div class="form-group" v-if="enableCollection">
      <label>Collection Open Time</label>
      <b-form-timepicker v-model="collection_open_time" locale="en"></b-form-timepicker>
      <span class="form-text text-muted">Choose the collection opening time.</span>
    </div>
    <div class="form-group" v-if="enableCollection">
      <label>Collection Close Time</label>
      <b-form-timepicker v-model="collection_close_time" locale="en"></b-form-timepicker>
      <span class="form-text text-muted">Choose the collection closing time.</span>
    </div>

    <!-- Next Day Closing -->
    <div class="form-group" v-if="next">
      <label>Next Day Closing?</label>
      &nbsp;&nbsp;
      <input v-model="next_day" type="checkbox" />
      <span class="form-text text-muted"
        >A new record will be entered for the next day if marked.</span
      >
    </div>

    <!-- Delivery Available -->

    <!-- Accept Order Before Closing -->
    <div>
      <label>Accept order before closing</label>
      <input
        type="number"
        min="0"
        style="border: 1px solid black"
        class="col-sm-8 form-control-solid form-control-lg"
        v-model="accept_before_closing"
        placeholder="Accept order before"
      />
      <b-form-select
        class="col-sm-4"
        v-model="timing"
        :options="timings"
      ></b-form-select>
      <span class="form-text text-muted">
        Set how many minutes before closing time a customer can make an order. For
        example, if the business closes at 7 PM and this value is set to 10,
        customers can place orders until 6:50 PM.
      </span>
    </div>

    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="cancel">Cancel</b-button> &nbsp;&nbsp;
      <b-button @click="save">Save</b-button>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { TIMINGS } from "@/core/services/store/config.module";

export default {
  name: "OpeningTimeForm",
  props: ["record"],
  data() {
    return {
      timing: "",
      days: [
        { value: "Monday", text: "Monday" },
        { value: "Tuesday", text: "Tuesday" },
        { value: "Wednesday", text: "Wednesday" },
        { value: "Thursday", text: "Thursday" },
        { value: "Friday", text: "Friday" },
        { value: "Saturday", text: "Saturday" },
        { value: "Sunday", text: "Sunday" },
      ],
      timings: ["hour", "minute"],
      day: null,
      next: false,
      next_day: false,
      enableDelivery: true, // Default to true for new records
      enableCollection: true, // Default to true for new records
      delivery_open_time: "",
      delivery_close_time: "",
      collection_open_time: "",
      collection_close_time: "",
      collection_available: "1",
      delivery_available: "1",
      accept_before_closing: "",
      business_timing_id: null,
    };
  },
  watch: {
    // Check if closing time is before opening time for delivery or collection
    delivery_close_time() {
      this.checkNextDay("delivery");
    },
    collection_close_time() {
      this.checkNextDay("collection");
    },
    // Reset timings when toggles are turned off
    enableDelivery(newVal) {
      if (!newVal) {
        this.delivery_open_time = null;
        this.delivery_close_time = null;
      }
    },
    enableCollection(newVal) {
      if (!newVal) {
        this.collection_open_time = null;
        this.collection_close_time = null;
      }
    },
  },
  methods: {
    checkNextDay(type) {
      this.next = false;
      if (type === "delivery" && this.delivery_open_time && this.delivery_close_time) {
        if (this.delivery_close_time < this.delivery_open_time) {
          this.next = true;
        }
      } else if (type === "collection" && this.collection_open_time && this.collection_close_time) {
        if (this.collection_close_time < this.collection_open_time) {
          this.next = true;
        }
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (!this.day) {
        Swal.fire("Validation!", "Day must be selected", "warning");
        return;
      }

      if (this.enableDelivery && (!this.delivery_open_time || !this.delivery_close_time)) {
        Swal.fire("Validation!", "Delivery timings must be entered", "warning");
        return;
      }

      if (this.enableCollection && (!this.collection_open_time || !this.collection_close_time)) {
        Swal.fire("Validation!", "Collection timings must be entered", "warning");
        return;
      }

      if (!this.timing) {
        Swal.fire(
          "Validation!",
          "Please select the timing type to accept order before closing",
          "warning"
        );
        return;
      }

      if (
        (this.delivery_close_time && this.delivery_close_time <= this.delivery_open_time) ||
        (this.collection_close_time && this.collection_close_time <= this.collection_open_time)
      ) {
        if (!this.next_day) {
          Swal.fire("Validation!", "Invalid Open and Close time", "error");
          return;
        }
      }

      this.$store
        .dispatch(TIMINGS, {
          day: this.day,
          next_day: this.next_day,
          delivery_open_time: this.enableDelivery ? this.delivery_open_time : null,
          delivery_close_time: this.enableDelivery ? this.delivery_close_time : null,
          collection_open_time: this.enableCollection ? this.collection_open_time : null,
          collection_close_time: this.enableCollection ? this.collection_close_time : null,
          collection_available: this.enableCollection,
          delivery_available: this.enableDelivery,
          accept_before_closing: this.accept_before_closing,
          timing: this.timing,
          business_timing_id: this.business_timing_id,
        })
        .then((resp) => {
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            this.$emit("update");
          } else {
            Swal.fire("Error", resp.message, "error");
          }
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
          }
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.record && Object.keys(this.record).length > 0) {
            console.log('record',this.record)
        // Editing an existing record
      this.day = this.record.day;
      this.delivery_open_time = this.record.delivery_open_time;
      this.delivery_close_time = this.record.delivery_close_time;
      this.collection_open_time = this.record.collection_open_time;
      this.collection_close_time = this.record.collection_close_time;
      this.collection_available = this.record.collection_available;
      this.delivery_available = this.record.delivery_available;
      this.accept_before_closing = this.record.accept_before_closing;
      this.timing = this.record.timing;
      this.business_timing_id = this.record.business_timing_id;

      // Set toggles based on existing timings
      this.enableDelivery = !!this.delivery_open_time && !!this.delivery_close_time;
      this.enableCollection = !!this.collection_open_time && !!this.collection_close_time;
    } else {
      // Adding a new record
      this.enableDelivery = true; // Default to true for new records
      this.enableCollection = true; // Default to true for new records
    }
  },
};
</script>
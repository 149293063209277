<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-5 font-weight-bold text-dark">
      Opening Timings <br />
      <small>Click add to create a new time slot or edit below.</small>
    </h4>
    <b-button       v-if=" $hasPermission('manage system settings') "
    style="float: right; margin-top:-3%;" v-b-modal.modal-1 @click="editData({})">Add Opening Timings</b-button>
    <br>  
    <div class="table-responsive mt-5">
      <table
          class="
              table
              table-head-custom 
              table-vertical-center
              table-head-bg
              table-borderless
            "
      >
        <thead>
        <tr class="text-left">
          <th style="min-width: 120px">Day</th>
          <th style="min-width: 120px">Delivery Open Time</th>
          <th style="min-width: 120px">Delivery Close Time</th>
          <th style="min-width: 120px">Collection Open Time</th>
          <th style="min-width: 120px">Collection Close Time</th>
          <th style="min-width: 100px">Delivery</th>
          <th style="min-width: 100px">Collection</th>
          <th style="min-width: 100px">Order Before Closing</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, i) in items">
          <tr v-bind:key="i">
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.day }}</span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.delivery_open_time }}</span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.delivery_close_time }}</span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.collection_open_time }}</span>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.collection_close_time }}</span>
            </td>
            <td>
              <b-form-checkbox v-model="item.delivery_available == 1" name="flavour-2a" disabled></b-form-checkbox>
            </td>
            <td>
              <b-form-checkbox v-model="item.collection_available == 1" name="flavour-2a" disabled></b-form-checkbox>
            </td>
            <td>
              <span class="text-dark-75 d-block font-size-lg">{{ item.accept_before_closing }} {{ item.timing }}</span>
            </td>
            <td>
              <div style="display:flex">

                <a       v-if=" $hasPermission('manage system settings') "
                v-b-modal.modal-1 @click="editData(item)" class="btn btn-light-primary font-size-xl"><i class="flaticon2-pen"></i></a>
                &nbsp;&nbsp;
                <a       v-if=" $hasPermission('manage system settings') " 
                 @click="deleteData(item)" class="btn btn-light-danger font-size-xl"><i class="flaticon-delete"></i></a>  
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-1" ref="opening-time-modal" title="Add Time Slot" hide-footer>
      <OpeningTimeForm @cancel="cancel" @update="getTimings" :record="data"></OpeningTimeForm>
    </b-modal>
    <form
      class="form"
      novalidate="novalidate"
      id="openingTimeForm"
      ref="form"
    >
    <button
        style="display: none;"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Submit
      </button>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import OpeningTimeForm from "@/view/pages/business/form-components/OpeningTimeForm";
import { GET_TIMINGS, DELETE_TIMING } from "@/core/services/store/config.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";

export default {
  name: "OpeningTimes",
  components: {
    OpeningTimeForm,
  },
  data() {
    return {
      items: [],
      data: {},
    };
  },
  methods: {
    editData(data) {
      this.data = data;
    },
    deleteData(data) {
      this.$store
        .dispatch(DELETE_TIMING, data)
        .then(() => {
          this.getTimings();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideModal() {
      this.$refs["opening-time-modal"].hide();
    },
    getTimings() {
      this.items = [];
      this.$store
        .dispatch(GET_TIMINGS)
        .then((resp) => {
          this.items = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.hideModal();
    },
    onSubmit() {
      this.$emit('validation', true);
      this.$refs.form_submit.click();
    },
  },
  mounted() {
    this.getTimings();
    const form = KTUtil.getById("openingTimeForm");
    this.fv = formValidation(form, {
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
};
</script>